<template>

  <div>
    <b-card sub-title="wersja obowiązująca od 19 stycznia 2022 r.">
      <b-card-text>
        <h3 class="text-center"><strong>Polityka prywatności serwisu&nbsp;</strong><a href="https://www.tomaszkrawczyk.pl"><strong>www.tomaszkrawczyk.pl</strong></a></h3>
        <p>&nbsp;</p>
        <p>Niniejszy dokument określa politykę prywatności serwisu www.tomaszkrawczyk.pl, dostępnego pod adresem www.tomaszkrawczyk.pl. Administratorem Serwisu jest Sonia Radosz, prowadzącą działalność gospodarczą pod firmą TomaszKrawczyk Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail: kontakt@tomaszkrawczyk.pl.</p>
        <p>&nbsp;</p>
        <p>Dbam o Twoją prywatność i chcę, abyś w czasie korzystania z usług serwisu czuł się komfortowo. Dlatego też poniżej prezentuję Ci najważniejsze informacje o zasadach przetwarzania Twoich danych osobowych oraz plikach cookies, kt&oacute;re są wykorzystywane przez nasz Serwis. Dane przetwarzane są w zgodzie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 oraz ustawą z 10 maja 2018 r. o ochronie danych osobowych.</p>
        <p>&nbsp;</p>
        <p><strong>&sect; 1 Informacja o przetwarzaniu danych</strong></p>
        <p>&nbsp;</p>
        <p>Na podstawie art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.4.2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), wskazuję, że:</p>
        <p>&nbsp;</p>
        <p>Jeśli zamierzasz założyć konto użytkownika oraz korzystać z funkcjonalności serwisu &ndash; zostaniesz poproszony o podanie Twoich danych osobowych. Podanie danych osobowych jest dobrowolne, jednak niezbędne do korzystania z oferowanych usług w pełnym zakresie funkcjonalności, w tym zakupu treści cyfrowych i korzystania z nich.</p>
        <p>&nbsp;</p>
        <p>Administratorem Twoich danych osobowych jest Administrator Serwisu.</p>
        <p>&nbsp;</p>
        <p>Przetwarzanie Twoich danych osobowych odbywać się będzie na podstawie art. 6 ust. 1 lit. a) i b) RODO i wyłącznie w celach związanych z funkcjonowaniem serwisu oraz prowadzoną sprzedażą treści cyfrowych. Po zakończeniu świadczenia usługi i korzystania z treści cyfrowych przez użytkownika zebrane dane poddane zostaną archiwizacji.</p>
        <p>&nbsp;</p>
        <p>Dane osobowe będą przechowywane przez czas świadczenia usługi i korzystania z treści cyfrowych przez użytkownika, a następnie w celach archiwizacyjnych przez okres niezbędny na podstawie przepis&oacute;w powszechnie obowiązujących w razie kontroli instytucji państwowych.</p>
        <p>&nbsp;</p>
        <p>Posiadasz pełne prawo dostępu do treści swoich danych osobowych, prawo do ich sprostowania, usunięcia oraz prawo do ograniczenia ich przetwarzania. Ponadto także prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność przetworzenia dokonanego przed takim cofnięciem, prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych.</p>
        <p>&nbsp;</p>
        <p>W sytuacji gdy uznasz, że przetwarzanie Twoich danych osobowych narusza przepisy RODO, przysługuje Ci prawo wniesienia skargi do Prezesa &nbsp; Urzędu &nbsp; &nbsp;Ochrony &nbsp; Danych &nbsp; Osobowych (ul. Stawki 2, 00-193 Warszawa).</p>
        <p>&nbsp;</p>
        <p>Podanie przez Ciebie danych osobowych w minimalnym zakresie umożliwiającym założenie konta użytkownika jest wymagane celem rozpoczęcia procedury zakupu online treści cyfrowych. Ich niepodanie skutkować będzie niemożliwością świadczenia usługi. Dane osobowe zbierane są m. in. w celu zapewnienia zgodności korzystania z serwisu z postanowieniami regulaminu, w tym przestrzegania przepis&oacute;w prawa autorskiego czy ograniczeń w liczbie os&oacute;b z dostępem do konta użytkownika.</p>
        <p>&nbsp;</p>
        <p>Korzystanie z serwisu www.tomaszkrawczyk.pl oznacza zgodę na przekazanie administratorowi serwisu informacji o adresie IP użytkownika. W przypadku utworzenia konta w serwisie i korzystania z dostarczonych treści cyfrowych adres IP użytkownika rejestrowany jest w bazie danych celem zapewnienia zgodności korzystania z zakupionych treści cyfrowych, w szczeg&oacute;lności zobowiązania użytkownika do osobistego korzystania z treści cyfrowych, zgodnie z postanowieniami regulaminu serwisu. Korzystanie z treści cyfrowych serwisu wymaga udostępnienia swojego adresu IP i jest dobrowolne. Brak udostępnienia adresu IP skutkuje jednak niemożliwością korzystania z funkcjonalności serwisu.</p>
        <p>&nbsp;</p>
        <p>Twoje dane osobowe nie będą przekazywane w wykonaniu usługi świadczonej przez Administratora żadnym odbiorcom. Dane osobowe nie będą przekazywane do państw trzecich ani organizacji międzynarodowych. Dane osobowe udostępniane przy zakładaniu konta użytkownika są poufne i nie są udostępniane osobom trzecim. Wgląd do nich ma wyłącznie Administrator.</p>
        <p>&nbsp;</p>
        <p>Uzyskane dane osobowe nie będą przetwarzane w żadnym momencie w spos&oacute;b zautomatyzowany i nie będą przedmiotem profilowania.</p>
        <p>&nbsp;</p>
        <p>Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i techniczne zgodne z przepisami polskiego prawa oraz stosujemy szyfrowanie połączenia za pomocą certyfikatu SSL.</p>
        <p>&nbsp;</p>
        <p>Zasady przetwarzania danych osobowych oraz inne postanowienia związane z korzystaniem z witryny umieszczone są także w Regulaminie.</p>
        <p>&nbsp;</p>
        <p><strong>&sect; 2 Ciasteczka</strong></p>
        <p>&nbsp;</p>
        <p>Serwis korzysta z tzw. plik&oacute;w cookies (ciasteczek). Są to niewielkie pliki, kt&oacute;re są zapisywane w pamięci Twojego urządzenia (komputera, telefonu, itd.), m.in. w celu umożliwienia Ci korzystania z wszystkich funkcjonalności Serwisu.</p>
        <p>&nbsp;</p>
        <p>Pliki cookies nie wpływają na funkcjonowanie Twojego urządzenia. W każdej chwili możesz je usunąć korzystając z wbudowanych opcji swojej przeglądarki internetowej.</p>
        <p>&nbsp;</p>
        <p>W tym Serwisie ciasteczka wykorzystywane są do cel&oacute;w:</p>
        <p>&bull; zapamiętywania informacji o Twojej sesji</p>
        <p>&bull; statystycznych.</p>
        <p>&bull; marketingowych</p>
        <p>&bull; udostępnienia Ci pozostałych funkcjonalności Serwisu</p>
        <p>&nbsp;</p>
        <p>Aby dowiedzieć się jak zarządzać plikami cookies, w tym jak wyłączyć ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy Twojej przeglądarki.</p>
        <p>&nbsp;</p>
        <p><strong>&sect; 3 Usługi Zewnętrzne</strong></p>
        <p>&nbsp;</p>
        <p>Mając na celu ulepszanie funkcjonowania serwisu i jego rozw&oacute;j dopasowany do Twoich potrzeb, Serwis jest zintegrowany z usługą analizującą Tw&oacute;j ruch w witrynie. Usługę tę wykorzystujemy do cel&oacute;w statystycznych, co pomaga lepiej dostosować się do Twoich potrzeb.</p>
        <p>&nbsp;</p>
        <p>Z polityką prywatności Google Analytics możesz zapoznać się pod adresem http://www.google.com/intl/pl/policies/privacy. Serwis jest zintegrowany r&oacute;wnież z usługą marketingową Google.</p>
        <p>&nbsp;</p>
        <p><strong>&sect; 4 Kontakt z Administratorem</strong></p>
        <p>&nbsp;</p>
        <p>W razie woli kontaktu z Administratorem z jakimkolwiek zapytaniem dotyczącym polityki prywatności czy funkcjonowaniem serwisu lub świadczonymi treściami cyfrowymi, napisz na:</p>
        <p>ChemMaster Sonia Radosz</p>
        <p>Wodzisławska 4</p>
        <p>44-240 Żory</p>
        <p>kontakt@tomaszkrawczyk.pl</p>
      </b-card-text>
    </b-card>
    <b-card sub-title="wersja obowiązująca od 23 lipca 2020 r. do 18 stycznia 2022 r.">
      <b-card-text>
        <p>Niniejszy dokument określa politykę prywatności serwisu www.tomaszkrawczyk.pl, dostępnego pod adresem www.tomaszkrawczyk.pl. Administratorem Serwisu jest Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail: kontakt@tomaszkrawczyk.pl.</p>
        <p>Dbam o Twoją prywatność i chcę, abyś w czasie korzystania z usług serwisu czuł się komfortowo. Dlatego też poniżej prezentuję Ci najważniejsze informacje o zasadach przetwarzania Twoich danych osobowych oraz plikach cookies, które są wykorzystywane przez nasz Serwis. Dane przetwarzane są w zgodzie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 oraz ustawą z 10 maja 2018 r. o ochronie danych osobowych.</p>
        <p><strong>§ 1 Informacja o przetwarzaniu danych</strong></p>
        <p>Na podstawie art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.4.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), wskazuję, że:</p>
        <p>Jeśli zamierzasz założyć konto użytkownika oraz korzystać z funkcjonalności serwisu – zostaniesz poproszony o podanie Twoich danych osobowych. Podanie danych osobowych jest dobrowolne, jednak niezbędne do korzystania z oferowanych usług w pełnym zakresie funkcjonalności, w tym zakupu treści cyfrowych i korzystania z nich.</p>
        <p>Administratorem Twoich danych osobowych jest Administrator Serwisu.</p>
        <p>Przetwarzanie Twoich danych osobowych odbywać się będzie na podstawie art. 6 ust. 1 lit. a) i b) RODO i wyłącznie w celach związanych z funkcjonowaniem serwisu oraz prowadzoną sprzedażą treści cyfrowych. Po zakończeniu świadczenia usługi i korzystania z treści cyfrowych przez użytkownika zebrane dane poddane zostaną archiwizacji.</p>
        <p>Dane osobowe będą przechowywane przez czas świadczenia usługi i korzystania z treści cyfrowych przez użytkownika, a następnie w celach archiwizacyjnych przez okres niezbędny na podstawie przepisów powszechnie obowiązujących w razie kontroli instytucji państwowych.</p>
        <p>Posiadasz pełne prawo dostępu do treści swoich danych osobowych, prawo do ich sprostowania, usunięcia oraz prawo do ograniczenia ich przetwarzania. Ponadto także prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność przetworzenia dokonanego przed takim cofnięciem, prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych.</p>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
    }
  },
}
</script>

<style>

</style>
